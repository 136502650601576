import React, { useState } from 'react';
import {
  SectionSubheadline,
  Options,
  Option,
  SectionGroup,
} from '../../common';
import './survey.scss';
import { useIntl, FormattedMessage } from 'gatsby-plugin-react-intl';
import { CHECKOUT_SURVEY_ANSWERED } from '../../../webapi/WebApi';
import client from '@/webapi/client';
import { useCheckoutState } from '../../../hooks/useCheckoutState';

export const Survey = () => {
  const intl = useIntl();
  const { checkoutState } = useCheckoutState();
  const [selected, setSelected] = useState('');
  const [answered, setAnswered] = useState(false);

  const surveyAnswers = [
    {
      str: intl.formatMessage({
        id: 'checkout-survey.online-advertising',
        defaultMessage: 'Online advertising',
      }),
      key: 'online_advertising',
    },
    {
      str: intl.formatMessage({
        id: 'checkout-survey.outdoor-advertising',
        defaultMessage: 'Outdoor advertising',
      }),
      key: 'outdoor_advertising',
    },
    {
      str: intl.formatMessage({
        id: 'checkout-survey.online-search',
        defaultMessage: 'Online search (ex. Google, Bing)',
      }),
      key: 'online_search',
    },
    {
      str: intl.formatMessage({
        id: 'checkout-survey.social-media',
        defaultMessage: 'Social media',
      }),
      key: 'social_media',
    },
    {
      str: intl.formatMessage({
        id: 'checkout-survey.acquintance',
        defaultMessage: 'Friend / family / colleague',
      }),
      key: 'acquintance',
    },
    {
      str: intl.formatMessage({
        id: 'checkout-survey.tv',
        defaultMessage: 'TV',
      }),
      key: 'tv',
    },
    {
      str: intl.formatMessage({
        id: 'checkout-survey.press',
        defaultMessage: 'Press',
      }),
      key: 'press',
    },
  ];

  const onSubmit = async (awareness: string) => {
    await client.mutate({
      mutation: CHECKOUT_SURVEY_ANSWERED,
      variables: { checkoutId: checkoutState.id, awareness },
    });

    setTimeout(() => {
      setAnswered(true);
    }, 100);
  };

  return (
    <div className="survey">
      <div className={answered ? 'showThanks' : 'hideThanks'}>
        <SectionSubheadline bold>
          <FormattedMessage
            id="checkout-survey.thanks"
            defaultMessage="Thanks!"
          />
        </SectionSubheadline>
      </div>
      <div className={answered ? 'hideQs' : 'showQs'}>
        <SectionSubheadline bold>
          <FormattedMessage
            id="checkout-survey.headline"
            defaultMessage="How did you first hear about Dance?"
          />
        </SectionSubheadline>
        <Options>
          <div className="survey-answers">
            {surveyAnswers.map((a) => (
              <div className="survey-answer" key={a.key}>
                <Option
                  id={a.key}
                  section="Survey Answers"
                  onClick={() => {
                    setSelected(a.key);
                    onSubmit(a.key);
                  }}
                  selected={selected === a.key}
                  withCheckbox
                >
                  <SectionGroup small>{a.str}</SectionGroup>
                </Option>
              </div>
            ))}
          </div>
        </Options>
      </div>
    </div>
  );
};
