import { IntlShape } from 'gatsby-plugin-react-intl';

import {
  HAMBURG_DELIVERY_BOUNDS,
  MUNICH_DELIVERY_BOUNDS,
  VIENNA_DELIVERY_BOUNDS,
  PARIS_DELIVERY_BOUNDS,
  BERLIN_DELIVERY_BOUNDS,
  DANCE_AREA,
} from '../config/constants';
import type { Area } from '@/webapi/gen/graphql';

export type DeliveryBounds = {
  south: number;
  west: number;
  north: number;
  east: number;
};

export type AreaDetail = {
  deliveryCity: string;
  deliveryAreaLink: string;
  deliveryBounds: DeliveryBounds;
};

// MULTI-AREA-EXPANSION: return correct delivery details for new DanceArea
export const getDeliveryAreaDetails = (danceArea: Area): AreaDetail => {
  const areaDetails = {
    deliveryCity: capitalize(danceArea),
    deliveryAreaLink: `https://knowledge.s.dance.app/service-area/${danceArea.toLowerCase()}.png`,
  };
  let deliveryBounds: DeliveryBounds;

  switch (danceArea) {
    case DANCE_AREA.HAMBURG:
      deliveryBounds = HAMBURG_DELIVERY_BOUNDS;
      break;
    case DANCE_AREA.MUNICH:
      deliveryBounds = MUNICH_DELIVERY_BOUNDS;
      break;
    case DANCE_AREA.VIENNA:
      deliveryBounds = VIENNA_DELIVERY_BOUNDS;
      break;
    case DANCE_AREA.PARIS:
      deliveryBounds = PARIS_DELIVERY_BOUNDS;
      break;
    default:
      deliveryBounds = BERLIN_DELIVERY_BOUNDS;
  }
  return {
    ...areaDetails,
    deliveryBounds,
  };
};

export // HAMBURG => Hamburg
const capitalize = (s) => `${s[0].toUpperCase()}${s.substr(1).toLowerCase()}`;

export function cityName(intl: IntlShape, area: Area): string {
  switch (area) {
    case 'BERLIN':
      return intl.formatMessage({
        id: 'cities.berlin',
        defaultMessage: 'Berlin',
      });
    case 'HAMBURG':
      return intl.formatMessage({
        id: 'cities.hamburg',
        defaultMessage: 'Hamburg',
      });
    case 'MUNICH':
      return intl.formatMessage({
        id: 'cities.munich',
        defaultMessage: 'Munich',
      });
    case 'VIENNA':
      return intl.formatMessage({
        id: 'cities.vienna',
        defaultMessage: 'Vienna',
      });
    case 'PARIS':
      return intl.formatMessage({
        id: 'cities.paris',
        defaultMessage: 'Paris',
      });
    default:
      return area;
  }
}
