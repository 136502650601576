import React from 'react';
import { SectionSubheadline, Paragraph } from '@/components/common';
import { AppstoreBadge } from './badges/appstore-badge';
import { PlaystoreBadge } from './badges/playstore-badge';
import { FormattedMessage } from 'react-intl';
import './download-apps.scss';

export const DownloadApps = ({
  station,
  vehicle,
}: {
  station: boolean;
  vehicle: string;
}) => (
  <div className="download-app-wrapper">
    <SectionSubheadline bold spacing="small">
      <FormattedMessage
        id="checkout-success.download-app"
        defaultMessage="Download our app"
      />
    </SectionSubheadline>
    {station ? (
      <>
        <Paragraph noSpacing>
          <FormattedMessage
            id="checkout-success.download-app-subheadline"
            defaultMessage="You'll need the Dance app to"
          />
        </Paragraph>
        {APP_BENEFITS(vehicle).map((benefit) => (
          <Paragraph
            noSpacing
            className="download-app-benefit"
            key={benefit.id}
          >
            {benefit.label}
          </Paragraph>
        ))}
      </>
    ) : (
      <Paragraph noSpacing>
        <FormattedMessage
          id="checkout-success.download-app-summary"
          defaultMessage="You'll need the Dance app to get the most out of your ride."
        />
      </Paragraph>
    )}

    <div className="download-app-badges">
      <a
        href="https://apps.apple.com/us/app/dance-bikes/id1557432083"
        target="_blank"
        rel="noreferrer"
        className="ios"
      >
        <div className="app-badge">
          <AppstoreBadge />
        </div>
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=co.dance.rider"
        target="_blank"
        rel="noreferrer"
        className="android"
      >
        <div className="app-badge">
          <PlaystoreBadge />
        </div>
      </a>
    </div>
  </div>
);

const APP_BENEFITS = (vehicle: string) => {
  return [
    {
      id: 'benefit-id',
      label: (
        <FormattedMessage
          id="checkout-success.download-app-benefit-id"
          defaultMessage="Verify your identity"
        />
      ),
    },
    {
      id: 'benefit-address',
      label: (
        <FormattedMessage
          id="checkout-success.download-app-benefit-address"
          defaultMessage="See the pickup address"
        />
      ),
    },
    {
      id: 'benefit-pick-up',
      label: (
        <FormattedMessage
          id="checkout-success.download-app-benefit-pick-up"
          defaultMessage="Pick up your bike"
          values={{ vehicle }}
        />
      ),
    },
    {
      id: 'benefit-contact',
      label: (
        <FormattedMessage
          id="checkout-success.download-app-benefit-contact"
          defaultMessage="Use services & contact us"
        />
      ),
    },
  ];
};
