import React, { Dispatch, SetStateAction, useEffect } from 'react';
import {
  ParagraphHeadline,
  Paragraph,
  SectionHeadline,
} from '../../common/text';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import { useCheckoutState } from '../../../hooks/useCheckoutState';
import {
  checkoutPrice,
  discountName,
  getAddons,
  getFee,
  getDiscount,
  formatAmount,
  formatMonthly,
  formatYearly,
} from '@/helpers/pricing';
import { addonName } from '@/helpers/i18n';
import VehicleModel from '../../../components/checkout/vehicle-model';
import { isInsuranceAddon } from '../entry/insurance/shared';
import {
  Discount,
  PaymentScheme,
  Prepayment,
  PriceDetail,
} from '@/webapi/gen/graphql';
import CheckboxIcon from '@/icons/checkbox-round.inline.svg';
import CheckboxCheckedIcon from '@/icons/checkbox-checked.inline.svg';
import { Section } from '@/components/common';
import { UNKNOWN_HELMET_SIZE } from '@/config/constants';

const PurchaseDetailsComponent = ({
  paymentScheme,
  setPaymentScheme,
}: {
  paymentScheme: PaymentScheme;
  setPaymentScheme?: Dispatch<SetStateAction<string>>;
}) => {
  const { checkoutState } = useCheckoutState();
  const intl = useIntl();
  const discount = getDiscount(checkoutState);
  const addons = getAddons(checkoutState);
  const deliveryFee = getFee(checkoutState, 'DELIVERY_FEE');
  const signupFee = getFee(checkoutState, 'SIGNUP_FEE');
  const { yearlyPrepayment } = checkoutState.priceDetail;

  return (
    <div className="payment-purchase-wrapper">
      <ParagraphHeadline>
        <FormattedMessage
          id="payment.purchase"
          defaultMessage="Your selection"
        />
      </ParagraphHeadline>
      <Paragraph noSpacing>
        <span>
          <VehicleModel /> ·{' '}
          {checkoutState.subscriptionType === 'FLEX' ? (
            <FormattedMessage
              id="subscription-type.flex"
              defaultMessage="Flex"
            />
          ) : (
            <FormattedMessage
              id="subscription-type.annual"
              defaultMessage="Annual"
            />
          )}
        </span>
      </Paragraph>

      <AddonSummary
        addons={addons}
        phone={checkoutState.payload.phoneHolder}
        helmetSize={checkoutState.payload.helmetSize}
      />

      <Section>
        <SectionHeadline>
          <FormattedMessage
            id="payment.whatyoullpay"
            defaultMessage="What you'll pay"
          />
        </SectionHeadline>

        {yearlyPrepayment ? (
          <YearlyPrepaymentSection
            priceDetail={checkoutState.priceDetail}
            yearlyPrepayment={yearlyPrepayment}
            discount={discount}
            paymentScheme={paymentScheme}
            signupFee={!!signupFee}
            setPaymentScheme={setPaymentScheme}
          />
        ) : (
          <PaymentSection discount={discount} />
        )}

        {signupFee && (
          <SignupFee
            amount={formatAmount(signupFee.amount, intl.locale)}
            waived={yearlyPrepayment && paymentScheme !== 'MONTHLY'}
          />
        )}

        {deliveryFee && (
          <DeliveryFee amount={formatAmount(deliveryFee.amount, intl.locale)} />
        )}

        {yearlyPrepayment && paymentScheme === 'MONTHLY' && (
          <YearlyPrepaymentDisclaimer />
        )}
      </Section>
    </div>
  );
};

const YearlyPrepaymentSection = ({
  priceDetail,
  yearlyPrepayment,
  discount,
  paymentScheme,
  signupFee,
  setPaymentScheme,
}: {
  priceDetail: PriceDetail;
  yearlyPrepayment: Prepayment;
  discount?: Discount;
  paymentScheme: PaymentScheme;
  signupFee: boolean;
  setPaymentScheme?: Dispatch<SetStateAction<string>>;
}) => {
  const intl = useIntl();
  const yearlyPrice = formatYearly(intl, yearlyPrepayment.amount);
  const monthlyPrice = formatMonthly(intl, priceDetail.monthlyPrice);

  useEffect(() => {
    sessionStorage.setItem('paymentScheme', paymentScheme);
  }, [paymentScheme]);

  return (
    <>
      <div className="purchase-detail-wrapper">
        {setPaymentScheme ? (
          <div className="prepayment-choices">
            <PrepaymentChoice
              type={'YEARLY_PREPAID'}
              selected={paymentScheme === 'YEARLY_PREPAID'}
              onClick={() => setPaymentScheme('YEARLY_PREPAID')}
            >
              <PrepaymentOptionYearly
                price={yearlyPrice}
                waiveSignupFee={signupFee}
                percentOff={yearlyPrepayment.percentOff}
              />
            </PrepaymentChoice>
            <hr className="payment-hr" />
            <PrepaymentChoice
              type={'MONTHLY'}
              selected={paymentScheme === 'MONTHLY'}
              onClick={() => setPaymentScheme('MONTHLY')}
            >
              <PrepaymentOptionMonthly
                price={!discount ? monthlyPrice : null}
              />
            </PrepaymentChoice>
            <Discounts discount={discount} yearlyPrepayment={true} />
            <div className="purchase-detail-monthly-to-yearly-price">
              {`( = ${formatYearly(intl, yearlyPrepayment.amountForMonthly)})`}
            </div>
          </div>
        ) : (
          <div className="purple">
            {paymentScheme === 'YEARLY_PREPAID' && (
              <PrepaymentOptionYearly
                price={yearlyPrice}
                waiveSignupFee={signupFee}
              />
            )}
            {paymentScheme === 'MONTHLY' && (
              <>
                <PrepaymentOptionMonthly
                  price={!discount ? monthlyPrice : null}
                />
                <Discounts discount={discount} yearlyPrepayment={true} />
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const PrepaymentOptionYearly = ({
  price,
  waiveSignupFee,
  percentOff,
}: {
  price: string;
  waiveSignupFee: boolean;
  percentOff?: number;
}) => {
  if (waiveSignupFee) {
    return (
      <div>
        <div className="purchase-detail-line">
          <span className="bold">
            <FormattedMessage
              id="payment.billed-yearly"
              defaultMessage="Billed yearly"
            />
          </span>
          <span className="bold">{price}</span>
        </div>
        <div className="purchase-detail-line">
          {percentOff && percentOff > 0 && (
            <div className="discount">
              <FormattedMessage
                id="payment.billed-yearly-discount-plus-waived-signup-fee"
                defaultMessage="-{percentOff}% and no sign up fee"
                values={{ percentOff: percentOff }}
              />
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="purchase-detail-line">
        <span className="bold">
          <FormattedMessage
            id="payment.billed-yearly"
            defaultMessage="Billed yearly"
          />
          {percentOff && percentOff > 0 && !waiveSignupFee && (
            <span className="discount inline">-{percentOff}%</span>
          )}
        </span>
        <span className="bold">{price}</span>
      </div>
    );
  }
};

const PrepaymentOptionMonthly = ({ price }) => {
  return (
    <div className="purchase-detail-line">
      <span className="bold">
        <FormattedMessage
          id="payment.billed-monthly"
          defaultMessage="Billed monthly"
        />
      </span>
      {price && <span className="bold">{price}</span>}
    </div>
  );
};

const PaymentSection = ({ discount }) => {
  return (
    <div className="purchase-detail-wrapper">
      <Discounts discount={discount} yearlyPrepayment={false} />
    </div>
  );
};

const DeliveryFee = ({ amount }) => {
  return (
    <div className="purchase-detail-wrapper purple">
      <div className="purchase-detail-line bold">
        <span>
          <FormattedMessage
            id="payment.delivery-fee"
            defaultMessage="Delivery fee"
          />
        </span>
        <span>{amount}</span>
      </div>
    </div>
  );
};

const SignupFee = ({ amount, waived }) => {
  if (waived) {
    return (
      <div className="purchase-detail-wrapper waived">
        <div className="purchase-detail-line bold">
          <span>
            <FormattedMessage
              id="payment.sign-up-fee"
              defaultMessage="Sign-up fee"
            />
          </span>
          <span>{amount}</span>
        </div>
      </div>
    );
  }
  return (
    <div className="purchase-detail-wrapper purple">
      <div className="purchase-detail-line bold">
        <span>
          <FormattedMessage
            id="payment.sign-up-fee"
            defaultMessage="Sign-up fee"
          />
        </span>
        <span>{amount}</span>
      </div>
    </div>
  );
};

const YearlyPrepaymentDisclaimer = () => {
  return (
    <div className="disclaimer-monthly-prepayment">
      <div>
        <FormattedMessage
          id="payment.disclaimer-monthly-prepayment"
          defaultMessage="You will be invoiced on a monthly basis for a minimum term of 1 year."
        />
      </div>
    </div>
  );
};

const AddonSummary = ({ addons, phone, helmetSize }) => {
  const intl = useIntl();
  const hasInsurance = addons.some((a) => isInsuranceAddon(a.type));

  return (
    <div className="purchase-addon-summary">
      {addons.map((addon) =>
        addon.type === 'phoneHolder' ? (
          <Paragraph key={addon.type} noSpacing>{`+ ${intl.formatMessage(
            {
              id: 'payment.phone-holder-summary',
              defaultMessage: 'Phone holder: {phone}',
            },
            { phone }
          )}`}</Paragraph>
        ) : addon.type === 'helmet' ? (
          <Paragraph key={addon.type} noSpacing>{`+ ${addonName(
            intl,
            addon.type
          )} ${
            helmetSize && helmetSize !== UNKNOWN_HELMET_SIZE
              ? `(${helmetSize})`
              : ''
          }`}</Paragraph>
        ) : (
          <Paragraph key={addon.type} noSpacing>{`+ ${addonName(
            intl,
            addon.type
          )}`}</Paragraph>
        )
      )}

      {!hasInsurance && (
        <Paragraph noSpacing>{`+ ${intl.formatMessage({
          id: 'payment.insurance-basic',
          defaultMessage: 'Basic insurance',
        })}`}</Paragraph>
      )}
    </div>
  );
};

const PrepaymentChoice = ({ type, selected, onClick, children }) => {
  const id = `prepayment-choice-${type}`;

  return (
    <label
      className={`prepayment-choice ${selected ? 'selected' : ''}`}
      htmlFor={id}
      data-test-id={id}
      data-testid={id}
      onClick={onClick}
    >
      <input
        className="prepayment-choice-input"
        type="radio"
        id={id}
        name={`Prepayment choice ${type}`}
        onChange={onClick}
        checked={selected}
      />
      <div className="prepayment-choice-label">
        <div>{selected ? <CheckboxCheckedIcon /> : <CheckboxIcon />}</div>
        <div className="prepayment-choice-children">{children}</div>
      </div>
    </label>
  );
};

const Discounts = ({ discount, yearlyPrepayment }) => {
  const intl = useIntl();
  const { checkoutState } = useCheckoutState();

  return (
    <div
      className={
        yearlyPrepayment
          ? 'discounts-yearly-prepayment'
          : 'discounts-no-prepayment'
      }
    >
      {discount && (
        <div>
          <div className="purchase-detail-line">
            <span>
              <FormattedMessage
                id="payment.purchase"
                defaultMessage="Your selection"
              />
            </span>
            <span className="price">
              {formatAmount(
                checkoutState.priceDetail.monthlyPrice,
                intl.locale
              )}
            </span>
          </div>
          <hr className="payment-hr slim" />
          <div className="purchase-detail-line">
            <span>
              {discountName(
                intl,
                discount.name,
                discount.category ?? undefined
              )}
            </span>
            <span className="price">
              {formatAmount(discount.amount, intl.locale)}
            </span>
          </div>
          <hr className="payment-hr slim" />
        </div>
      )}

      {(!yearlyPrepayment || (yearlyPrepayment && discount)) && (
        <MonthlyFee
          price={formatMonthly(intl, checkoutPrice(checkoutState, 'MONTHLY'))}
          discount={discount}
        />
      )}

      {discount && discount.duration !== 'FOREVER' && (
        <div>
          <div className="purchase-detail-line">
            <span>
              <FormattedMessage
                id="payment.after-that"
                defaultMessage="After that"
              />
            </span>
            <span className="price">
              {formatMonthly(intl, checkoutState.priceDetail.monthlyPrice)}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

const MonthlyFee = ({
  price,
  discount,
}: {
  price: string;
  discount?: Discount;
}) => {
  let monthlyFee;

  if (!discount) {
    monthlyFee = (
      <FormattedMessage id="payment.monthly-fee" defaultMessage="Monthly fee" />
    );
  } else {
    const duration =
      discount.duration === 'REPEATING' && discount.validForMonths === 1
        ? 'ONCE'
        : discount.duration;

    switch (duration) {
      case 'ONCE':
        monthlyFee = (
          <FormattedMessage
            id="payment.first-month"
            defaultMessage="First month"
          />
        );
        break;
      case 'REPEATING':
        monthlyFee = (
          <FormattedMessage
            id="payment.first-n-months"
            defaultMessage="First {n} months"
            values={{ n: discount.validForMonths! }}
          />
        );
        break;
      case 'FOREVER':
      default:
        monthlyFee = (
          <FormattedMessage
            id="payment.monthly-fee"
            defaultMessage="Monthly fee"
          />
        );
    }
  }

  const result = (
    <div className="purchase-detail-line monthly-fee">
      <span className="name">{monthlyFee}</span>
      <span className="price">{price}</span>
    </div>
  );

  return result;
};

export const PurchaseDetails = React.memo(PurchaseDetailsComponent);
