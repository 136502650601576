import React from 'react';
import { Paragraph, ParagraphHeadline } from '@/components/common/text';
import { FormattedMessage } from 'react-intl';
import InfoIcon from '@/icons/info.inline.svg';

import './verify-id.scss';
import { Button } from 'react-bootstrap';

export const VerifyIdStation = ({ idVerificationUrl }) => (
  <div className="id-verification-station">
    <Paragraph className="id-verification-station-instructions">
      <FormattedMessage
        id="checkout-success.id-verification-now-headline"
        defaultMessage="You can also verify your identity now using this link (the app is still required to use your vehicle)."
      />
    </Paragraph>
    <div className="id-verification-button-wrapper">
      <Button
        className="button"
        variant="dark"
        data-test-id="verify-id"
        href={idVerificationUrl!}
      >
        <FormattedMessage
          id="id-verification-button"
          defaultMessage="Verify your identity"
        />
      </Button>
    </div>
  </div>
);

export const VerifyIdHome = () => (
  <div className="id-verification-home">
    <ParagraphHeadline>
      <InfoIcon className="id-verification-icon" />
      <FormattedMessage
        id="checkout-success.id-verification"
        defaultMessage="Please bring a valid form of identification with you."
      />
    </ParagraphHeadline>
  </div>
);
